import { db } from '../firebase.js'
import { doc, collection, getDoc } from "firebase/firestore";
import { useParams } from 'react-router';
import $ from 'jquery'; 
import { useEffect, useState} from 'react';
import { Component } from 'react';
import React from 'react';
import NotFound from './notFound';
import LatestProducts from './latestProducts'
import { getProducts } from '../databaseManager'
import { format } from 'moment';
import MessageModal from './messageModal'
import ProductDescriptionFields from './productDescriptionFields.js';
import { Helmet } from 'react-helmet-async'; 
import AdaptiveImage from './adaptiveImage.js'

const moment = require('moment'); // Import Moment.js

function formatDate(dateString) {
  const date = moment.utc(dateString, "YYYYMMDD,HH:mm:ss+0000"); // Specify format
  const localDate = date.local(); // Convert to user's local timezone
  return localDate.format('MMM DD, YYYY h:mm A'); // Format in desired format
}

function formatJoinedDate(dateString) {
  const date = moment.utc(dateString, "YYYYMMDD,HH:mm:ss+0000"); // Specify format
  const localDate = date.local(); // Convert to user's local timezone
  return localDate.format('MMM DD, YYYY'); 
}

function formatPhoneNumber(phoneNumber) {
  // Ensure only digits are considered
  const digits = phoneNumber.replace(/\D/g, "");

  // Format based on length (handling common cases)
  if (digits.length === 10) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
  } else if (digits.length === 7) {
    return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  } else {
    // Handle other lengths or return unformatted if invalid
    return digits;
  }
}
const constants = {
  EXCLUDE_FROM_PRODUCT_TERMS: ['unknown', 'not applicable', 'not provided', 'not available', 'not specified'],
  RESERVED_FIELDS: ['title', 'description', 'price', 'category', 'primarycategory'],
  EBAY_AFFILIATE_LINK: '?mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5339041905&customid=&toolid=10001&mkevt=1'
};

function buildSpecs(map) {
  const addAfter = [];
  const newArray = [];
  for (const item in map) {
    if (!constants.RESERVED_FIELDS.includes(item.toLowerCase())) {
      if (!constants.EXCLUDE_FROM_PRODUCT_TERMS.includes(map[item].toLowerCase()) && (map[item].length > 0)) {
        newArray.push([item, map[item]])
      } else {
        addAfter.push(item);
      }
    }
  }

  for (const item of addAfter) {
    newArray.push([item, map[item]])
  }
  return newArray;
}

export default function Product() {
  const params = useParams();
  const customer = params['customer'];
  const [data, setData] = useState({});
  const [widgetData, setWidgetData] = useState({});
  const [sellerItems, setSellerItems] = useState({});
  const [mainImage, setMainImage] = useState(data.mainImage);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleImageClick = (newImage) => {
    setMainImage(newImage);
  };

  useEffect(() => {
    // Dynamically set document title for browsers
    document.title = data.title;
    
  }, [data.title]);


  useEffect(() => {
    let product = params['product'];
    const custColRef = collection(db, "customers")
    const custDocRef = doc(custColRef, customer)
    const prodColRef = collection(custDocRef, "products")
    const prodDocRef = doc(prodColRef, product);
  
    getDoc(custDocRef).then(cdSnap => {
      getDoc(prodDocRef).then(docSnap => {
        if (docSnap.exists()) {
          const custData = cdSnap.data()
          const tempData = docSnap.data()
          const results = tempData.editedResults ? tempData.editedResults : tempData.chatGptResponse
          results['noBgImageUrls'] = tempData.noBgImageUrls;
          results['thumbnailUrls'] = [];
          
          if (tempData.mainImages) {
            tempData.mainImages.forEach((image) => {
              if (image.isHidden == false) {
                results['thumbnailUrls'].push(image.urlString)
              }
            })
            if (results['thumbnailUrls'].length > 0 ) {
              results['mainImage'] = results['thumbnailUrls'][0];
            } 
          } else {
            if (tempData.noBgImageUrls.length > 0 ) {
              results['mainImage'] = tempData.noBgImageUrls[0];
            } 
            results['thumbnailUrls'] = tempData.thumbnailUrls;
          }
          results['created'] = formatDate(tempData.created);
          if (tempData.updated) {
            results['updated'] = formatDate(tempData.updated);
          } else {
            results['updated'] = ""
          }
          if (tempData.isFree) {
            results['price'] = 'FREE'
          } else {
            results['price'] = '$' + results.price.replace('$', '')
          }

          results['email'] = custData.email;
          if (custData.phoneNumber) {
            results['phone'] = formatPhoneNumber(custData.phoneNumber);
          }
          results['name'] = custData.name;
          if (custData.created) {
            results['joinedDate'] = formatJoinedDate(custData.created);
          }

          if (custData.locationDescription) {
            results['location'] = custData.locationDescription
          } else {
            results['location'] = '-'
          }
          if (tempData['gptProductSpecs']) {
            results['specs'] = buildSpecs(tempData['gptProductSpecs']['gptProductSpecs'])
          } else {
            results['specs'] = []
          }
          
          if (tempData['gptCategories']) {
            results['category'] = tempData['gptCategories']['primaryCategory']
          } else {
            results['category'] = ""
          }
          
          if (tempData.consolidatedData) {
            results['title'] = tempData.consolidatedData['Title']
            results['description'] = tempData.consolidatedData['Description']
            results['price'] = '$' + tempData.consolidatedData['Price'].replace('$', '')
            results['specs'] = buildSpecs(tempData.consolidatedData)
          }

          setData(results);
          setMainImage(results['mainImage']);
        } else {
          console.log("No such document!");
        }  
      });
    });
  }, []);

  useEffect(() => {
      getProducts(4, null, null, null, (productList, snap) => { 
          setWidgetData(productList);
      });
    }, []);


  useEffect(() => {
    let customer = params['customer'];
    getProducts(3, null, customer, null, (productList, snap) => { 
          setSellerItems(productList);
    });
  }, []);
  
  return ( 
    <div className="Product">
      <Helmet>
        {/* Dynamic meta tags for social media */}
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.description} />
        <meta property="og:image" content={data.mainImage} />
        <meta property="og:url" content={window.location.href} />
        {/* Add more tags as needed */}
      </Helmet>

    { data ? ( 
      <>
      <div className="section product">
        <div className="container-default w-container">
          <div className="product-wrapper">
            <div
              data-w-id="04b2ffac-b49e-c82e-a71d-ac156beb467e"
              style={{
                WebkitTransform:
                  "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1
              }}
              className="split-content product-content"
            >
            <h1>{data.title}</h1>  

            { data.thumbnailUrls?.length > 0 ? (
            <>
            <a
              href="#"
              className="image-wrapper product-main-image w-inline-block w-lightbox"
            >
            <AdaptiveImage src={mainImage}/>
            </a>
            { data.thumbnailUrls?.length > 1 ? ( <>
              <div className="w-dyn-list">
                <div role="list" className="product-more-images-grid w-dyn-items">
                { data.thumbnailUrls?.map((c) => (
                  <div role="listitem" className="product-more-image w-dyn-item" key={c}>
                    <a
                      href="#"
                      className="image-wrapper product-more-images w-inline-block w-lightbox"
                      onClick={() => handleImageClick(c)}
                    >
                    <img src={c} alt="" className="image product-more-images" key={c} />
                    </a>
                  </div>
                  )
                  )}
                  </div>
                  </div>
                </>) : (<></>) }
                  </>
              ) : (
              <>
                <div className="empty-state small w-dyn-empty">
                  <div>No photos found.</div>
                </div>
              </>
              )}
              <div className="divider product" />
              <h2
                data-w-id="0d164821-5a69-d93a-4a88-b638db7891d5"
                style={{
                  WebkitTransform:
                    "translate3d(0, 40PX, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 40PX, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 40PX, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 40PX, 0) scale3d(0.9, 0.9, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1
                }}
                className="title about-the-product"
              >
              About the Product
              </h2>
              <div
                data-w-id="37e0a16d-8990-18f7-7de3-def2c21688c5"
                style={{
                  WebkitTransform:
                    "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1
                }}
                className="rich-text about-product w-richtext"
              >
              <p>{data.description}</p>
              <strong>Category: </strong>{data.category}
              {data.specs && (
                <ProductDescriptionFields data={data.specs} />
              )}
              </div>
            </div>
            <div className="sidebar-product">
              <div
                data-w-id="87fc89d8-83bb-2538-3a97-3da5fc17effd"
                style={{
                  WebkitTransform:
                    "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  opacity: 1
                }}
                className="sidebar-product-sticky"
              >
                <div className="purchase-product-wrapper">
                  <div>
                    <div className="top-content purchase-product-option-content">
                      <label htmlFor="" className="purchase-product-label">
                        Price:
                      </label>
                      <div className="purchase-product-price">{data.price}</div>
                    </div>
                    {/* <div>
                   {  isModalOpen == false  && (<button className="w-commerce-commerceaddtocartbutton button-primary add-to-cart"
                       onClick={handleOpenModal}>Message Seller </button>)}
                    {isModalOpen && <MessageModal isModalOpen={true} product={ params['product'] } customer={ params['customer'] } onClose={() => setIsModalOpen(false)} />}
                    </div> */}
                    {/*
                    <input
                      type="submit"
                      data-node-type="commerce-add-to-cart-button"
                      data-loading-text="Adding to cart..."
                       aria-busy="false"
                      aria-haspopup="dialog"
                      className="w-commerce-commerceaddtocartbutton button-primary add-to-cart"
                      value={messageSeller}
                    />
                    <a
                      data-node-type="commerce-buy-now-button"
                      data-default-text="Make Offer"
                      data-subscription-text="Subscribe now"
                      aria-busy="false"
                      aria-haspopup="false"
                      className="w-commerce-commercebuynowbutton button-secondary buy-now"
                      href="checkout.html"
                    >
                      Make Offer
                    </a>
                    */}
                    <div
                      style={{ display: "none" }}
                      className="w-commerce-commerceaddtocartoutofstock"
                      tabIndex={0}
                    >
                      <div>This product is out of stock.</div>
                    </div>
                    <div
                      aria-live=""
                      data-node-type="commerce-add-to-cart-error"
                      style={{ display: "none" }}
                      className="w-commerce-commerceaddtocarterror"
                    >
                      <div
                        data-node-type="commerce-add-to-cart-error"
                        data-w-add-to-cart-quantity-error="Product is not available in this quantity."
                        data-w-add-to-cart-general-error="Something went wrong when adding this item to the cart."
                        data-w-add-to-cart-mixed-cart-error="You can’t purchase another product with a subscription."
                        data-w-add-to-cart-buy-now-error="Something went wrong when trying to purchase this item."
                        data-w-add-to-cart-checkout-disabled-error="Checkout is disabled on this site."
                        data-w-add-to-cart-select-all-options-error="Please select an option in each set."
                      >
                        Product is not available in this quantity.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="17c08c01-39fb-da5b-56e6-257da3c8a07a"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 1
                  }}
                  className="product-specs"
                >
                  <h3 className="title product-specs-title">Product Details</h3>
                  <div className="product-specs-wrapper">
                    <img
                      src="images/icon-product-specs-1-market-template.svg"
                      alt="Calendar Icon - Market Webflow Template"
                      className="icon-product-specs"
                    />
                    <div className="product-specs-content-wrapper">
                      <div>Created:&nbsp; { data.created }</div>
                      <div className="product-specs-text" />
                    </div>
                  </div>
                  { data.updated != "" ? ( 
                    <div className="product-specs-wrapper">
                    <img
                      src="images/icon-product-specs-1-market-template.svg"
                      alt="Calendar Icon - Market Webflow Template"
                      className="icon-product-specs"
                    />
                    <div className="product-specs-content-wrapper">
                      <div>Updated:&nbsp; { data.updated }</div>
                      <div className="product-specs-text" />
                    </div>
                  </div>
                    ) : (<></>)}
                  {/* <div className="product-specs-wrapper">
                    <img
                      src="images/icon-product-specs-2-market-template.svg"
                      alt="File Icon - Market Webflow Template"
                      className="icon-product-specs"
                    />
                    <div className="product-specs-content-wrapper">
                      <div>Location:&nbsp;</div>
                      <div className="product-specs-text"> { data.location } </div>
                    </div>
                  </div> */}
                </div>
                <div
                  data-w-id="0f176af9-9ba9-827d-b8de-3819eaf82cd6"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    opacity: 0
                  }}
                  className="about-the-author-wrapper"
                >
                  {/* <h3 className="title about-the-author">About the seller</h3>
                  <a
                    data-w-id="39f6e743-9183-2a50-72a1-a983ad385299"
                    href="#"
                    className="about-the-author w-inline-block"
                  >
                  <div className="top-content about-the-author-top-content">
                    <div className="about-the-author-left-wrapper">
                      <div className="image-wrapper about-the-author-image">
                        <img
                          src="/images/empty-profile.jpg"
                          alt=""
                          className="image about-the-author-image"
                        />
                      </div>
                      <div className="about-author-top-content">
                        <div className="about-the-author-name-wrapper">

                          
                          { data.name ? (<div className="about-the-author-name">{data.name}</div>) : (<div className="about-the-author-name"/>)}
                          
                          <div className="about-the-author-job">
                          
                            { data.joinedDate ? (<>Member since: {data.joinedDate}</>) : (<>Member since:&nbsp;Dec 2023</>)} 
                            <br />
                            <br />
                            Customer Score -
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="paragraph about-the-author-paragraph">
                  { data.email ? (<>Email: {data.email}</>) : (<>Email:&nbsp;-</>)}
                  <br />
                  { data.phone ? (<>Phone: {data.phone}</>) : (<>Phone:&nbsp;-</>)} 
                  </p>
                  </a> */}
                  { sellerItems.length > 1000000 ? (
                    <>              
                  <div className="more-products-title-wrapper">
                    <div className="title more-products">
                      More Products by the Seller &nbsp;
                    </div>
                    <div className="title more-products" />
                  </div>
                  <div className="w-dyn-list">
                    
                    <div
                      role="list"
                      className="more-product-by-author-grid w-dyn-items"
                    >
                    
                      { sellerItems.map((c) => (    
                        
                      <div
                        role="listitem"
                        className="more-products-by-author w-dyn-item"
                        key={c.noBgImageUrls[0]}
                      >
                        <a
                          data-w-id="f8c75cda-4b8c-257e-91f6-f3d7100fc75c"
                          href={"/products/" + customer}
                          className="more-product-by-author-wrapper w-inline-block"
                        >
                        <div className="image-wrapper more-products-by-author">
                          <img
                            src={c.noBgImageUrls[0]}
                            alt=""
                            className="image more-products-by-author"
                          />
                        </div>
                        <div className="more-products-by-author-content">
                          <h3 className="title more-products-by-author"> {c.title}</h3>
                          <div className="badge more-products-by-author">
                            <div>{c.price}</div>
                            <div />
                          </div>
                        </div>
                        </a>
                      </div>

                      ))}
                    </div>
                  </div>
                  </>
                )  : (<></>) }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-default w-container">
            <div
              data-w-id="026101c1-e070-6f0b-a18b-af2b48c69203"
              style={{
                WebkitTransform:
                  "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 60PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1
              }}
              className="divider"
            />
          </div>
      </div>
      </>  ) : (<><div className="empty-state small w-dyn-empty">
                      <div>Item not found.</div>
                    </div></>) }
    <>
    {/* {<LatestProducts data={widgetData} isWidget={true} />} */}
    </>
    </div>
  );
}

