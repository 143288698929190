import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getProducts } from '../databaseManager'
import SearchBar from './searchBar'
import ProductList from './productList'

export default function ProductsHome() {
  const params = useParams();
  const pageSize = 10;
  const searchQuery = params['string'];
  const customer = params['customer'];
  const [data, setData] = useState([]);
  const [lastSnap, setLastSnap] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  function shouldOverride() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('gw');
    return code === '321'
  }
  // Initial fetch
  useEffect(() => {
    setLoading(true);
    getProducts(pageSize, null, customer, searchQuery, (productList, snap) => { 
      setData(productList);
      setLastSnap(snap);
      setHasMore((productList.length > 0) || (snap != null));
      setLoading(false);
    }, shouldOverride());
  }, [getProducts, customer, searchQuery]);

  // Infinite scroll fetch
  // Debounce function
function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Usage with your scroll event handler
useEffect(() => {
  const debouncedHandleScroll = debounce(() => {
    // Check if near the bottom of the page to trigger loading more items
    const threshold = 100; // Adjust threshold as needed
    const currentPosition = window.innerHeight + document.documentElement.scrollTop;
    const pageHeight = document.documentElement.offsetHeight;

    if (pageHeight - currentPosition < threshold && !loading && hasMore) {
      setLoading(true);
      getProducts(pageSize, lastSnap, customer, searchQuery, (productList, snap) => {
        setData(prev => [...prev, ...productList]);
        setHasMore((productList.length > 0) || (snap != null));
        setLastSnap(snap);
        setLoading(false);
      }, shouldOverride());
    }
  }, 100); // 100ms debounce time

  window.addEventListener('scroll', debouncedHandleScroll);
  return () => window.removeEventListener('scroll', debouncedHandleScroll);
}, [loading, getProducts, lastSnap, customer, searchQuery, pageSize, hasMore]);

  useEffect(()=>{
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'))
  })

  return (
    <div className="ProductsHome">
    <div className="section hero-shop">
        <div className="container-default w-container">
          <div className="hero-shop-wrapper">
            <div className="split-content hero-shop-left">
              <h1
                className="title hero-shop"
              >
              { searchQuery ? (
                <>
                Products containing '{searchQuery}'
                </>
                ) : (<>All Products </>) }
              </h1>
               { searchQuery ? (<></>) : (
                <>
              <p
              >
                {/* The Best Sellers Are On Shopik */}
              </p>
              </>
                ) }
            </div>
             {<SearchBar />}
          </div>
        </div>         
        <div className="section latest-products">
          <div className="container-default w-container">
          { <ProductList data={data} searchQuery={searchQuery}/>}
          </div>
          </div>
          <div
          role="navigation"
          aria-label="List"
          className="w-pagination-wrapper pagination"
          >

        {loading && <div>Loading...</div>}
        {!hasMore && <div></div>}
          </div>
        </div>
    </div>
  );
}
